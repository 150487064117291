<template>
  <div class="scontrol">
    <div class = "filterBar flexboxb">
      <div class = "flexboxs">
        <div class = "filterItem" style="width: 200px;">
          <Cascader
            :value="farmScene"
            :data="farmOptions" 
            :load-data="getChildren" 
            trigger="click"  
            style="min-width: 200px;"
            placeholder="选择基地/场景"
            placement="top"
            :clearable="false"
            @on-change="farmSceneChange"
          />
        </div>
        <div class = "filterItem">
          <Cascader
            :data="controlTypeOptions" 
            :load-data="getControlCategoryList" 
            change-on-select
            placeholder="选择场景类型/控制类别"
            @on-change="(val, selData) => {filterChange('sensor', 'cas', val, selData)}"
          />
        </div>
        <div class = "filterItem">
          <Select 
            class="selectMid"
            :value="filter.switchType"
            placeholder="选择开关类型" 
            label-in-value 
            @on-change="val => {filterChange('switchType', 'select', val)}" 
            @on-clear="()=>{filterClear('switchType', 'select')}" filterable
          >
            <Option :value="1">脉冲</Option>
            <Option :value="2">旋钮</Option>
          </Select>
        </div>
        <div class = "filterItem">
          <Select 
            class="selectMid"
            :value="filter.channelType"
            placeholder="选择厂商类型" 
            label-in-value 
            @on-change="({val})=>{filterChange('channelType', 'select', val)}" 
            @on-clear="()=>{filterClear('channelType', 'select')}" filterable
          >
            <Option v-for="(item, index) in channelTypeOptions" :value="item.value" :key="index">{{item.label}}</Option>
          </Select>
        </div>
      </div>
      <div v-if="accessData.sceneCtrAdd">
        <Button type = "primary" @click="controlNew">添加控制</Button>
      </div>
    </div>
    <div class="controlGrid">
      <Table border :columns="listCol" :loading="listLoading" :data="listData"></Table>
    </div>
    <div class="pageBar">
      <Page :total="page.total" :current="page.pageNo" :page-size="page.pageSize" show-elevator show-total @on-change ="pageChange"></Page>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Scontrol',
  props: {
    farmId: {
      type: Number,
      default: 0
    },
    sceneId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      listCol: [
        {
          title: '控制组名称',
          key: 'groupName',
          minWidth: 110,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) return c('span', row.groupName);
            return c('Input', {
              'class': {
                noPass: row.groupName ? false : true,
              },
              props: {
                value: row.groupName,
              },
              ref: 'cgroupNameInput',
              on: {
                'on-change': e => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      groupName: e.target.value
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        groupName: e.target.value
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.cgroupNameInput.focus();
                  })
                }
              }
            })
          }
        },
        {
          title: '场景类型/控制类别',
          key: 'controlTypeName',
          minWidth: 110,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.controlTypeName + ' / ' + row.controlCategoryName);
            row.id && (row.controlTypeArr = [+row.controlType, +row.controlCategory]);
            return c('Cascader', {
              props: {
                labelInValue: true,
                value: row.controlTypeArr,
                data: this.controlTypeOptions,
                loadData: this.getControlCategoryList,
              },
              'class': {
                noPass: row.controlTypeArr && row.controlTypeArr.length > 1 ? false : true,
              },
              ref: 'controlTypeCas',
              on: {
                'on-change': (val, selData) => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      controlTypeArr: val,
                      controlType: val[0],
                      controlCategory: val[1]
                    })
                  } else {
                    for (let i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        controlTypeArr: val,
                        controlType: val[0],
                        controlCategory: val[1]
                      })
                    }
                  }
                }
              }
            })
          }
        },
        {
          title: '罗拉设备',
          key: 'unicomName',
          minWidth: 110,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.unicomName);
            return c('Select', {
              attrs: {
                // value: this.newData.farmId,
              },
              props: {
                labelInValue: true,
                value: row.unicomGuid,
              },
              'class': {
                noPass: row.unicomGuid ? false : true,
              },
              on: {
                'on-change': val => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      unicomGuid: val.value,
                      unicomName: val.label
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        unicomGuid: val.value,
                        unicomName: val.label
                      })
                    }
                  }
                }
              }
            }, this.llOptions.map(el => 
              c('Option', {
                props: {
                  value: el.value,
                }
              }, el.label)
            ))
          }
        },
        {
          title: '通道类型/通道名称',
          key: 'channel',
          minWidth: 120,
          render: (m, {row}) => {
            let c = this.$createElement;
            if (!row.isE) return c('span', row.channelTypeName + ' / ' + row.channelName);
            row.id && (row.channelTypeArr = [+row.channelType, row.guid]);
            return c('Cascader', {
              props: {
                labelInValue: true,
                value: row.channelTypeArr,
                data: this.channelTypeOptions,
                loadData: (item, cb) => {
                  this.getChannelList(item, cb, {
                    id: row.id,
                    type: row.channelType,
                    no: row.channelNo,
                    name: row.channelName,
                    guid: row.guid,
                  })
                }
              },
              'class': {
                noPass: row.channelTypeArr && row.channelTypeArr.length > 1 ? false : true,
              },
              ref: 'cchannelTypeCas',
              on: {
                'on-change': (val, selData) => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      channelTypeArr: val,
                      channelType: val[0],
                      guid: val[1],
                      channelName: selData[1].label,
                      channelNo: +selData[1].num
                    })
                  } else {
                    for (let i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        channelTypeArr: val,
                        channelType: val[0],
                        guid: val[1],
                        channelName: selData[1].label,
                        channelNo: +selData[1].num
                      })
                    }
                  }
                }
              }
            })
          }
        },
        {
          title: '开关类型(控制名称)',
          key: 'switchTypeName',
          minWidth: 120,
          render: (c, {row}) => {
            if (!row.isE) return c('span', row.switchType === 1 ? `脉冲（${row.name}）` : `旋钮（${row.name}）`);
            row.id && (row.switchTypeArr = [+row.switchType, row.name]);
            return c('Cascader', {
              props: {
                labelInValue: true,
                value: row.switchTypeArr,
                data: this.switchTypeOptions,
              },
              'class': {
                noPass: row.switchTypeArr && row.switchTypeArr.length > 1 ? false : true,
              },
              ref: 'cswitchTypeCas',
              on: {
                'on-change': (val, selData) => {
                  if (!row.id) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      switchTypeArr: val,
                      switchType: val[0],
                      name: val[1],
                    })
                  } else {
                    for (let i = 0; i < this.listData.length; i++) {
                      this.listData[i].id === row.id && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        switchTypeArr: val,
                        switchType: val[0],
                        name: val[1],
                      })
                    }
                  }
                }
              }
            })

          }
        },
        {
          title: '开关状态',
          key: 'switchStatus',
          minWidth: 90,
          render: (c, {row}) => {
            if (!row.isE) return this.accessData.sceneCtrStatus ? c('i-switch', {
              props: {
                value: row.switchStatus,
                size: 'large',
                'true-value': '1',
                'false-value': '0',
                loading: this.switchLoading && row.id === this.switchingId,
              },
              on: {
                'on-change': e => {
                  this.changeControlSwitchStatus(e, {row})
                }
              }
            }, [
              c('span', {
                slot: 'open'
              }, '开启'),
              c('span', {
                slot: 'close'
              }, '关闭'),
            ]) : c('span', +row.switchStatus === 1 ? '开启' : '关闭');
            return c('span', '--');
          }
        },
        {
          title: '锁定状态',
          key: 'lockStatus',
          minWidth: 100,
          render: (c, {row}) => {
            if (!row.isE) return this.accessData.sceneCtrLock ? c('i-switch', {
              props: {
                value: row.lockStatus,
                size: 'large',
                'true-value': '0',
                'false-value': '1',
                loading: this.lockLoading && row.id === this.lockingId,
              },
              on: {
                'on-change': e => {
                  this.changeControlLockStatus(e, {row})
                }
              }
            }, [
              c('span', {
                slot: 'open'
              }, '正常'),
              c('span', {
                slot: 'close'
              }, '锁定'),
            ]) : c('span', row.lockName);
            return c('span', '--');
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 110,
          render: (c, {row}) => {
            return !row.isE ? 
              c('span', row.remark) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  props: {
                    value: row.remark,
                  },
                  on: {
                    'on-change': (e) => {
                      row.remark = e.target.value
                    }
                  }
                })
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 110,
          render: (c, {row}) => {
            if (row.isE) {
              return c('div', [
                c('Icon', {
                  attrs: {
                    title: '保存'
                  },
                  style: {
                    marginRight: '15px',
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-checkmark'
                  },
                  nativeOn: {
                    click: () => {
                      !row.id ? this.saveControlNew(row) : this.saveControlEdit(row);
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '取消'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-close'
                  },
                  nativeOn: {
                    click: () => {
                      !row.id ? this.cancelControlNew(row) : this.cancelControlEdit(row)
                    }
                  }
                }),
              ])
            } else {
              let delBtn = c('Icon', {
                attrs: {
                  title: '删除'
                },
                style: {
                  cursor: 'pointer',
                  color: '#08DDDD',
                  fontSize: '16px',
                },
                props: {
                  type: 'md-trash'
                }
              });
              return this.deleteLoading && this.deletingId === row.id ? c('Spin', {
                props: {
                  size: 'small'
                }
              }) : c('div', [
                this.accessData.sceneCtrMod && c('Icon', {
                  attrs: {
                    title: '编辑'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-brush'
                  },
                  nativeOn: {
                    click: () => {
                      this.controlEdit(row)
                    }
                  }
                }),
                this.accessData.sceneCtrDel && c('Poptip', {
                  props: {
                    confirm: true,
                    title: '确认删除？',
                    placement: 'left'
                  },
                  on: {
                    'on-ok': () => {
                      this.controlDel(row.id)
                    },
                    'on-cancel': () => {

                    }
                  }
                }, [delBtn])
              ])
            }
          }
        },
      ],
      listData: [],
      llOptions: [],
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10
      },
      switchTypeOptions: [
        {
          label: '旋钮',
          value: 2,
          children: [
            {
              label: '开/关',
              value: '开/关'
            }
          ]
        },
        {
          label: '脉冲',
          value: 1,
          children: [
            {
              label: '开',
              value: '开'
            },
            {
              label: '关',
              value: '关'
            },
            {
              label: '停',
              value: '停'
            },

          ]
        }
      ],
      controlTypeOptions: [],
      channelTypeOptions: [],
      filter: {},
      listLoading: false,
      deletingId: '',
      deleteLoading: false,
      switchLoading: false,
      lockingId: '',
      lockId: '',
      farmScene: [],
      farmOptions: [],
    }
  },
  computed: {
    accessData () {
      return {
        // 检测、控制、视频表格权限
        sceneCtrAdd: true, //this.$store.state.app.permissionsGroup.sceneCtrAdd,
        sceneCtrMod: true, //this.$store.state.app.permissionsGroup.sceneCtrMod,
        sceneCtrDel: true, //this.$store.state.app.permissionsGroup.sceneCtrDel,
        sceneCtrStatus: true, //this.$store.state.app.permissionsGroup.sceneCtrStatus,
        sceneCtrLock: true, //this.$store.state.app.permissionsGroup.sceneCtrLock,
      }
    }
  },
  methods: {
    getFarmList () {
      return this.$post(this.$api.FARM.LIST, {
        pageNo: 1,
        pageSize: 99999
      }).then(resp => {
        return this.farmOptions = resp.list.map(item => ({
          value: +item.farmId,
          label: item.name,
          children: [],
          loading: false,
        }));
      })
    },
    farmSceneChange (val, selData) {
      this.filter = {
        ...this.filter,
        farmId: val[0],
        sceneId: val[1]
      };
      this.getList(true)
    },
    getChildren (item, cb) {
      item.loading = true,
      this.$post(this.$api.FARM.SCENE_LIST, {
        farmId: item.value,
        pageSize: 99999,
        pageNo: 1,
      }).then(resp => {
        item.children = resp.list.map(elem => ({
          value: elem.sceneId,
          label: elem.name,
        }));
        item.loading = false;
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })
    },

    getSceneList () {
      return this.$post(this.$api.FARM.SCENE_LIST, {
        farmId: this.farmCurrentId,
        pageSize: 99999,
        pageNo: 1,
      }).then(resp => {
        return resp.list.map(el => el)
      }).catch(e => {
        
      })
    },
    // 获取场景类型（传感器类型）
    getControlTypeList () {
      this.$post(this.$api.COMMON.DICT, {
        name: '场景类型'
      }).then(resp => {
        var controlTypeOptions = []
        for (var val of resp) {
          controlTypeOptions.push({
            value: val.num,
            label: val.name,
            children: [],
            loading: false,
          })
        }
        this.controlTypeOptions = controlTypeOptions
      })
    },
    
    // 获取监测类别
    getControlCategoryList (item, cb) {
      item.loading = true;
      var name = ''
      switch (item.value) {
        case 1:
          name = '种植业控制器分类'
          break
        case 2:
          name = '养殖业控制器分类'
          break
        case 3:
          name = '水产业控制器分类'
          break;
      }
      this.$post(this.$api.COMMON.DICT, {
        name
      }).then(resp => {
        var controlCategoryOptions = [];
        for (var val of resp) {
          controlCategoryOptions.push({
            value: val.num,
            label: val.name
          })
        };
        item.children = controlCategoryOptions;
        item.loading = false,
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })
    },
    pageChange (pageNo) {
      this.page.pageNo = pageNo
      this.getList(true)
    },

    getLlOptions () {
      this.$post(this.$api.DEVICES.LUOLA_LIST, {
        pageSize: 9999,
        pageNo: 1,
      }).then(resp => {
        if (!resp.list) return;
        this.llOptions = resp.list.map(el => ({
          label: el.name,
          value: el.guid,
        }))
      })
    },

    // 获取厂商类型
    getChannelTypeOptions () {
      this.$post(this.$api.COMMON.DICT, {
        name: '设备厂商'
      }).then(resp => {
        this.channelTypeOptions = resp.filter(el => el.num !== 7 && el.num !== 8).map(item => {
          return {
            value: item.num,
            label: item.name,
            children: [],
            loading: false,
          }
        })
      })
    },

    getChannelList (item, cb, {id, type, no, name, guid}) {
      // 后台并未返回已绑定的通道，所以编辑填充表单时需要手动unshift列表中存在的通道数据
      item.loading = true;
      let api = '';
      let params = {
        sceneId: this.sceneId,
        isNotStatus: 1,
      };
      switch (item.value) {
        case 1:
          api = this.$api.AISLE.JY_AISLELIST;
          params = {
            ...params,
            doType: 1,
            pageNo: 1,
            pageSize: 99999,
          };
          break;
        case 4:
          api = this.$api.AISLE.SBW_AISLELIST;
          params = {
            pageNo: 1,
            pageSize: 99999,
          }
      }
      this.$post(api, {
        ...params
      }).then(resp => {
        if (item.value === 1) {
          item.children = resp.list.map(item => {
            return {
              value: item.guid,
              label: item.channelName,
              num: item.channelNo,
            }
          });
          id && item.children.unshift({
            value: guid,
            label: name,
            num: +no,
          });
        } else if (item.value === 4) {
          item.children = resp.list.map(item => {
            return {
              value: item.guid,
              label: item.nodeName,
              num: item.nodeAddress,
            }
          });
          id && item.children.unshift({
            value: guid,
            label: name,
            num: +no,
          });
        }
        
        item.loading = false,
        cb();
      }).catch(e => {
        item.loading = false;
        cb();
      })

    },

    filterChange (field, type, val, selData) {
      if (type === 'cas') {
        if (val.length === 1) {
          this.filter = {
            ...this.filter,
            sensorType: val[0],
          }
        } else if (val.length === 2) {
          this.filter = {
            ...this.filter,
            sensorType: val[0],
            sensorCategory: val[1],
          }
        } else {
          this.filter = {
            ...this.filter,
            sensorType: void 0,
            sensorCategory: void 0,
          }
        }
        this.getList(true);
      } else if (type === 'input') {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.filter = {
            ...this.filter,
            [field]: val.target.value ? val.target.value : void 0,
          };
          this.getList(true)
        }, 500);
      } else if (type === 'select') {
        this.filter = {
          ...this.filter,
          [field]: val.value
        }
        this.getList(true)
      }
    },

    filterClear (field, type) {
      if (type === 'select') {
        this.filter = {
          ...this.filter,
          [field]: void 0,
        };
        this.getList(true)
      }
    },

    getList(loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.CONTROL.CONTROL_LIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter,
      }).then(resp => {
        this.listData = resp.list.map(item => {
          return {
            ...item,
            farmId: +item.farmId,
          }
        });
        this.page = {
          total: +resp.total,
          pageNo: +resp.pageNo,
          totalPage: +resp.totalPage,
          pageSize: +resp.pageSize,
        }
        loadingShow && (this.listLoading = false);
      }).catch(e => {
        loadingShow && (this.listLoading = false);
      })
    },

    controlNew () {
      if (!this.filter.farmId || !this.filter.sceneId) {
        this.$Modal.info({
          title: '提示',
          content: '添加控制配置前，请先选择所属基地场景！',
          okText: '我知道了！'
        })
        return
      }
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
      })
    },

    saveControlNew (record) {
      let noPass = !record.groupName
      || !record.controlType
      || !record.controlCategory
      || !record.channelType
      || !record.unicomGuid
      || ((!record.channelNo && record.channelNo !== 0) || !record.channelName || !record.guid)
      || (!record.switchType || !record.name);
      if (noPass) return;
      this.listLoading = true;
      delete record.isE;
      this.$post(this.$api.CONTROL.CONTROL_NEW2, {
        ...record,
        lockStatus: 0,
        switchStatus: 0,
        farmId: this.filter.farmId,
        sceneId: this.filter.sceneId,
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })

    },

    cancelControlNew () {
      this.listData.shift()
    },
    
    controlEdit (record) {
      this.channelTypeOptions = this.channelTypeOptions.map(item => {
        if (item.value === +record.channelType) {
          return {
            ...item,
            children: []
          }
        } else {
          return item
        }
      })
      let listData = this.listData.map(item => {
        return item.id === record.id ? {...item, isE: true} : item
      })
      this.listData = listData;
      this.editData = {...record}
    },

    saveControlEdit (record) {
      let noPass = !record.groupName
      || !record.controlType
      || !record.controlCategory
      || !record.channelType
      || !record.unicomGuid
      || ((!record.channelNo && record.channelNo !== 0) || !record.channelName || !record.guid)
      || (!record.switchType || !record.name);
      if (noPass) return;
      this.listLoading = true;
      delete record.isE;
      this.$post(this.$api.CONTROL.CONTROL_EDIT2, {
        ...record,
        farmId: this.filter.farmId,
        sceneId: this.filter.sceneId,
        id: record.id
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })
    },

    cancelControlEdit (record) {
      this.listData = this.listData.map(item => {
        return item.id === record.id ? this.editData : item
      });
    },

    controlDel (id) {
      this.deletingId = id;
      this.deleteLoading = true;
      this.$post(this.$api.CONTROL.CONTROL_DEL, {
        id
      }).then(resp => {
        this.deleteLoading = false;
        this.getList(true)
      }).catch(e => {
        this.deleteLoading = false;
      })
    },

    // 控制表格开关状态切换
    changeControlSwitchStatus (e, params) {
      this.switchingId = params.row.id;
      this.switchLoading = true;
      this.$post(this.$api.CONTROL.CONTROL_SWITCH_STATUS, {
        id: params.row.id,
        switchStatus: Number(e)
      }).then(resp => {
        setTimeout(() => {
          this.switchLoading = false;
          this.getList(true)
        }, 5000)
      }).catch(e => {
        this.switchLoading = false;
      })
    },
    // 控制表格锁定状态切换
    changeControlLockStatus (e, params) {
      this.lockingId = params.row.id;
      this.lockLoading = true;
      this.$post(this.$api.CONTROL.CONTROL_LOCK_STATUS, {
        id: params.row.id,
        lockStatus: Number(e)
      }).then(resp => {
        this.lockLoading = false;
        this.getList(true);
      }).catch(e => {
        this.lockLoading = false;
      })
    },
  },
  mounted () {
    this.getFarmList().then(resp => {
      if (!this.farmId) {
        this.filter.farmId = resp[0] ? resp[0].value : void 0;
        this.getSceneList().then(res => {
          if (!this.sceneId) {
            this.filter.sceneId = res[0] ? res[0].sceneId : void 0;
          }
          this.farmScene = [this.filter.farmId, this.filter.sceneId];
          this.getList(true);
        })
      } else {
        this.filter = {
          farmId: this.farmId,
          sceneId: this.sceneId,
        }
        this.farmScene = [this.filter.farmId, this.filter.sceneId];
        this.getList(true);
      }
    })
    this.getControlTypeList();
    this.getChannelTypeOptions();
    this.getLlOptions()
  }
}
</script>
<style lang="less">
.scontrol {
  .controlGrid {
    margin: 10px 0;
  }
  .filterBar {
    .filterItem {
      margin-right: 10px;
    }
  }
  .pageBar {
    text-align: right;
  }
  .noPass {
    input {
      border: 1px solid #e4393c;
    }
    .ivu-select-selection {
      border: 1px solid #e4393c;
    }
    .ivu-input-number {
      border: 1px solid #e4393c;
      input {
        border: none;
      }
    }
  }
}
</style>


